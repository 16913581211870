<template>
  <CRow>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="getTitulo"
      color="dark"
      size="xl"
      :show.sync="modalActive"
      @click="modalActive=false"
    >
      <CRow class="mb-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton 
                color="danger"
                class="mr-1"
                size="sm" 
                @click="onBtnPdf()"
                >
                <CIcon name="cil-file-pdf"/>&nbsp; PDF 
            </CButton>
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
            <CButton
                color="watch"
                size="sm"  
                @click="onBtnExport(false)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; CSV
            </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CRow class="col-sm-12 col-lg-12 col-xl-12">
          <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2 pr-1 ml-2">{{$t('label.date')}}</label>
              <CCol sm="11" lg="2" xl="2" class="center-field px-1">
                  <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="false"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.fromDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                  >
                  <template #icon-calendar>
                      <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </CCol>
              <label class="col-sm-11 col-lg-auto col-xl-auto text-right mt-2">-</label>
              <CCol sm="11" lg="2" xl="2" class="center-field px-1 mb-2">
                  <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="false"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.toDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                  >
                  <template #icon-calendar>
                      <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </CCol>
              <CCol class="col-sm-11 col-lg-auto col-xl-auto center-field px-1" style="text-align: right;">
                  <CButton
                      color="watch"
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{content: $t('label.search')+' '+$t('label.dateRange'), placement: 'top-end'}" 
                      @click="getUserTransa()"
                      >
                      <CIcon name="cil-search" />
                  </CButton>
                  <CButton
                      color="wipe"
                      class="justify-content-end"
                      size="sm"
                      v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}" 
                      @click="refreshComponent()"
                      >
                      <CIcon name="cil-brush-alt" />
                  </CButton>
            </CCol>
          </CRow>
        <CCol sm="12">
          <dataTableExtended
         class="align-center-row-datatable"
            hover
            sorter
            small
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :items="dateFilteredItems"
            :fields="fields"
            :items-per-page="5"
            :active-page="activePage"
            pagination
          >
            <template #RowNumber="{ item }">
              <td>
                {{ item.RowNumber }}
              </td>
            </template>
            <template #FormatedDate="{ item }">
              <td class="text-center">
                {{ item.FormatedDate }}
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
    
      <template #footer>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="modalActive=false"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.exit')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </CRow>
  </template>
    
    <script>
    import { DateFormater, GenerateExcel , tableTextTranslatedHelpers } from '@/_helpers/funciones';
    import GeneralReport from '@/_mixins/generalReport';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    function fields() {
      return [
        { 
          key: 'RowNumber', 
          label: '#',
          _style: 'width:1%;',
          filter: false,
        },
        { key: 'TpTransactionName', label: this.$t('label.transactionType'),_classes:'text-center'},
        { key: 'ObjectDmlName', label: this.$t('label.businessProcess'),_classes:'text-center'},
        { key: 'EntityName', label: this.$t('label.entity'),_classes:'text-center'},
        { key: 'Motivo', label: this.$t('label.motive') ,_classes:'text-center'},
        { key: 'FormatedDate', label:this.$t('label.transactionHourDate'),_classes:'text-center'}
      ];
    }
    //Data
    function data() {
      return {
        modalActive: false,
        items: [],
        Loading: false,
        activePage: 1,
        startDate: 1325376000000,
        endDate: new Date().getTime(),
        titulo: '',
        //user:'',
        searchFilterDate:{
          fromDay:"",
          toDay:"",
        },
        previousDate: '',
        laterDate: '',
        rangeDateInit : ''
      }
    }
    
    //Methods
    function getUserTransa() {
      this.Loading = true;
      let dateStart = '';
      let dateEnd = '';
      let listado = Array;
     
      dateStart= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay);
      dateEnd= DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay);        
      
      let UserTransacJson = [{
          UserId: this.transaccionesUserId,
          DateStart: dateStart,
          DateEnd: dateEnd,
      }];
      
      this.$http.post("UserTransact-by-id", UserTransacJson, { root: 'UserTransacJson' })
      .then(response => {
        // if(Object.keys(this.items).length > 1){
        //   this.items = response.data.data;
        // }else{
        //   this.items = [];
        // }
        listado = [...response.data.data];
          let nulo = String(listado[0].Json);
          if (nulo=="null" || listado ==''){
              return false;
        }
        this.items = listado;
        this.Loading = false;
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.Loading = false;
      });
    }
  

    async function onBtnPdf(){
      this.Loading = true;
      let ReporPdf = [];
      let tableHeader = [];
      let title = '';
      if(this.nulo!="null" && this.dateFilteredItems.length !== 0) {

          title = this.$t('label.transacModalTitle')+' '+this.transaccionesUser.UserName;

          tableHeader.push({ title: '#', dataKey: 'Nro'});
          tableHeader.push({ title: this.$t('label.transactionType'), dataKey: 'TpTransactionName'});
          tableHeader.push({ title: this.$t('label.businessProcess'), dataKey: 'ObjectDmlName'});
          tableHeader.push({ title: this.$t('label.entity'), dataKey: 'EntityName'});
          tableHeader.push({ title: this.$t('label.motive'), dataKey: 'Error'});
          tableHeader.push({ title: this.$t('label.date'), dataKey: 'FormatedDate'});

          for (let i = 0; i < this.dateFilteredItems.length; i++) {
            ReporPdf.push({
              Nro: this.dateFilteredItems[i].Nro,
              TpTransactionName: this.dateFilteredItems[i].TpTransactionName ? this.dateFilteredItems[i].TpTransactionName : '',
              ObjectDmlName: this.dateFilteredItems[i].ObjectDmlName ? this.dateFilteredItems[i].ObjectDmlName : '',
              EntityName: this.dateFilteredItems[i].EntityName ? this.dateFilteredItems[i].EntityName : '',
              Error: this.dateFilteredItems[i].Error ? this.dateFilteredItems[i].Error : 'N/A',
              FormatedDate: this.dateFilteredItems[i].TransaRegDate? DateFormater.formatDateTimeWithSlash(this.dateFilteredItems[i].TransaRegDate):'N/A',
            });   
        }
        await this.getPdf(ReporPdf, title, false, tableHeader);
      }else{
        this.$notify({  
          group: 'container',
          title: '¡Error!',
          text: this.$t('label.noRecordsAvailbleReport'),
          type: "error" 
        });
      }
      this.Loading = false;   
    }

    async function onBtnExport(valor) {
      this.Loading = true;
      let currentDate = new Date();
      let rowData = [];
      let valores = [];
      let tableHeader = {
        label: [],
        key: [],
      };
      rowData = this.dateFilteredItems;
      valores[0] = this.$t('label.from')+': '+this.searchFilterDate.fromDay+' - '+this.$t('label.to')+': '+this.searchFilterDate.toDay;
      valores[1] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
      valores[2] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');

      this.fields.map(item => {
        tableHeader.label.push(item.label);
        tableHeader.key.push(item.key);
      })
      if(this.dateFilteredItems.length !== 0) {
          await this.getReporExcel(rowData,this.$t('label.transactionReport'),valor, valores, false, tableHeader);
      }else{
          this.$notify({  
              group: 'container',
              title: '¡Error!',
              text: this.$t('label.noRecordsAvailbleReport'),
              type: "error" 
          });
      }
      this.Loading = false;   
    }

    function getDateRange(){
      this.previousDate = DateFormater.formatDateRange('2023-01-01 00:00');
      this.laterDate = new Date();
      this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash('2023-01-01 00:00');
      this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
      this.getUserTransa();
    }

    function validateDateRange(date) {
      return date < this.previousDate || date > this.laterDate;
    }
   
     
    async function refreshComponent() {
      this.textSearch="";
      this.searchFilterDate.fromDay="";
      this.searchFilterDate.toDay="";
      this.items=[];
      //await this.getUserTransa();
    }
    function resetForm(){
      this.textSearch="";
      this.searchFilterDate.fromDay="";
      this.searchFilterDate.toDay="";
      this.items=[];
    }
    //Computeds
    function formatedItems() {
      return this.items.map((item) => Object.assign({}, item, {
        RowNumber: item.Nro,
        Motivo: item.Error ? item.Error:'N/A',
        FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
        StatusText: item.Status,
      }));
    }
    function dateFilteredItems() {
      return this.formatedItems.filter(item => {
        const date = new Date(item.TransaRegDate).getTime();
        return date >= this.startDate && date <= this.endDate;
      });
    }
   
    function getTitulo(){
      return this.titulo;
    }
    
    function tableText() {
      return tableTextTranslatedHelpers(this);
    }
    
    export default {
      name: 'transacciones-modal',
      props: {modal: Boolean, transaccionesUser: Object, transaccionesUserId: String},
      data,
      methods: {
        getUserTransa,
        getDateRange,
        onBtnPdf,
        onBtnExport,
        validateDateRange,
        refreshComponent,
        resetForm
      },
      mixins: [ReportesVisitas,GeneralReport],
      computed: {
        fields,
        formatedItems,
        dateFilteredItems,
        getTitulo,
        tableText
      },
      watch: {
        modal: function(val) {
          if(val){
            this.modalActive = val;
            this.getDateRange();
            //this.getUserTransa();
            //this.user = this.transaccionesUser.UserId;
            this.titulo = `${this.$t('label.transacModalTitle')}: ${this.transaccionesUser.UserName} ${this.transaccionesUser.UserLastName}`;
          }else{
            this.resetForm();
          }
        },
        modalActive: function(val) {
          this.$emit('update:modal', val);
        },
      }
    }
    </script>
    
    <style lang="scss" scoped>
    .mx-datepicker {
    width: 100%;
  }
    </style>