<template>
  <CModalExtended
    :title="getTitulo"
    color="dark"
    size="xl"
    :show.sync="modalActive"
  >
  <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <dataTableExtended
       class="align-center-row-datatable"
      hover
      small
      sorter
      column-filter
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      :items="formatedItems"
      :fields="fields"
      :items-per-page="5"
      :active-page="activePage"
      pagination
    >
      <template #RowNumber="{ item , index}">
        <td class="text-center">
          {{ index+1 }}
        </td>
      </template>
  
      <template #Status="{item}">
        <td class="text-center">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>
    </dataTableExtended>
  
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('label.exit')}}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
  <script>
  import { DateFormater } from '@/_helpers/funciones';
  import GeneralMixin from '@/_mixins/general';
  
  function fields(){
    return [
      { 
        key: 'RowNumber', 
        label: '#',
        _style: 'width:1%;',
        filter: false,
        _classes:'center-cell text-center',
      },
      { key: 'RoleName', label: this.$t('label.role'), _style:'width:20%;',_classes:'center-cell text-center',},
      { key: 'BranchName', label:  this.$t('label.branch'), _style:'width:20%;',_classes:'center-cell text-center',},
      { key: 'YardName', label: this.$t('label.yard'), _style:'width:20%;',_classes:'center-cell text-center',},
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:20%;',_classes:'center-cell text-center',},
      { key: 'FormatedDate', label: this.$t('label.date'), _classes:"text-center", _style:'width:10%;',_classes:'center-cell text-center',} ,
      { key: 'Status', label: this.$t('label.status'), _style:'width:1%; text-align:center;',_classes:'center-cell text-center',}
    ];
  }
  
  //Data
  function data() {
    return {
      modalActive: this.modal,
      items: [],
      Loading: false,
      activePage: 1,
      titulo: ''
    }
  }
  
  //Methods
  function getUserRoles(idUsuario) {
    this.Loading = true;
    this.$http.ejecutar('GET', 'UserRole-by-id', { UserId: idUsuario, Filter: 'ALL'})
    .then(response => {
      this.items = response.data.data;
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.Loading = false;
    });
  }
  
  function toggle(newVal) {
    if(newVal)
      this.getUserRoles(this.userData.UserId);
      this.modalActive = newVal;
  }
  
  //Computeds
  function formatedItems() {
    return this.items.map((item) => Object.assign({}, item, {
      RowNumber: item.Nro,
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : '',
      _classes: item.Status === 'ACTIVO'? '': 'table-danger'
    }));
  }
  function getTitulo() {
    return this.titulo;
  }
  
  export default {
    name: 'roles-modal',
    mixins: [GeneralMixin],
    props: {
      modal: Boolean,
      userData: {
        type: Object,
        default: () => { return {} }
      }
    },
    data,
    methods: {
      getUserRoles,
      toggle
    },
    computed: {
      fields,
      formatedItems,
      getTitulo
    },
    watch: {
      modal: function(val) {
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      },
      userData: function(newUser) {
        this.items = [];
        this.titulo = `${this.$t('label.userAssignedRoles')}: ${newUser.UserName} ${newUser.UserLastName}`;
      }
    }
  }
  </script>