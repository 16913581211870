<template>
  <div>
    <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="$t('label.nuevo')+' '+$t('label.user')"
      color="dark"
      size="lg"
      :close-on-backdrop="false"
      :show.sync="modalActive"
    >
      <form @submit.stop.prevent="submit" class="company-form">
        <CustomTabs :active-tab="tabIndex" @update:activeTab="handleTab" class="text-table">
          <CustomTab :title="$t('label.userIdentificationInfo')" :class="$v.$error ? 'tab-error' : ''">
            <CRow>
              <CCol sm="12">
                <CCard style="border: none;">
                  <CCardBody>
                    <CRow>
                      <CCol sm="6"> 
                        <CInput
                          v-model.trim="$v.user.UserCi.$model"
                          v-uppercase
                          horizontal
                          :invalid-feedback="errorMessage($v.user.UserCi)"
                          :is-valid="hasError($v.user.UserCi)"
                          size="sm"
                          :label="$t('label.ID')"
                          :placeholder="$t('label.ID')"
                          addLabelClasses="text-right required"
                          maxlength="10"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        />
                        <CInput
                          v-model.trim="$v.user.UserName.$model"
                          v-uppercase
                          horizontal
                          :invalid-feedback="errorMessage($v.user.UserName)"
                          :is-valid="hasError($v.user.UserName)"
                          size="sm"
                          :label="$t('label.name')+'S'"
                          :placeholder="$t('label.name')"
                          addLabelClasses="text-right required"
                          maxlength="50"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        />
                        <CInput
                          v-model.trim="$v.user.UserLastName.$model"
                          v-uppercase
                          horizontal
                          :invalid-feedback="errorMessage($v.user.UserLastName)"
                          :is-valid="hasError($v.user.UserLastName)"
                          size="sm"
                          :label="$t('label.lastName')+'S'"
                          :placeholder="$t('label.lastName')"
                          addLabelClasses="text-right required"
                          @change="generatedLogin"
                          maxlength="50"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        />
                        <CInput
                          v-model.trim="$v.user.Login.$model"
                          v-uppercase
                          horizontal
                          :invalid-feedback="errorMessage($v.user.Login)"
                          :is-valid="hasError($v.user.Login)"
                          size="sm"
                          label="LOGIN"
                          placeholder="LOGIN"
                          addLabelClasses="text-right required"
                          maxlength="50"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        />
                        <CInput
                          type="password"
                          v-model.trim="$v.user.Password.$model"
                          horizontal
                          :invalid-feedback="errorMessage($v.user.Password)"
                          :is-valid="hasError($v.user.Password)"
                          size="sm"
                          :label="$t('label.password')"
                          addLabelClasses="text-right required"
                          maxlength="20"
                          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                        />
                      </CCol>
                      <CCol sm="6">
                        <CSelect
                          :value.sync="$v.user.TpUserId.$model"
                          size="sm"
                          :invalid-feedback="errorMessage($v.user.TpUserId)"
                          :is-valid="hasError($v.user.TpUserId)"
                          :label="$t('label.userType')"
                          :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                          :placeholder="$t('label.select')"
                          :options="typesOptions"
                          addLabelClasses="text-right required"
                          :disabled="showCollapse"
                          @change="handleTpUserSelect"
                        >
                          <template #append>
                            <div class="d-flex align-items-center">
                              
                                <CButton
                                  :disabled="isCollapseOpen"
                                  style="height: calc(1.5em + 0.5rem + 2px);"
                                  size="sm"
                                  color="add"
                                  class="d-flex align-items-center mx-1"
                                  v-c-tooltip="{
                                    content: $t('label.add')+' '+$t('label.userType'),
                                    placement: 'top-end'
                                  }"
                                  @click.stop="openCollapse(true)"
                                >
                                  <CIcon name="cil-plus"/>
                                </CButton>
                                <CButton
                                  :disabled="isTpUserEditInvalid"
                                  style="height: calc(1.5em + 0.5rem + 2px);"
                                  size="sm"
                                  color="edit"
                                  class="d-flex align-items-center"
                                  v-c-tooltip="{
                                    content: $t('label.edit')+' '+$t('label.userType'),
                                    placement: 'top-end'
                                  }"
                                  @click.stop="openCollapse(false)"
                                >
                                  <CIcon name="pencil"/>
                                </CButton>
                            </div>
                          </template>
                        </CSelect>
                        <CCollapse :show="showCollapse">
                          <tp-user-collapse :edit="tpUserEdit" :tp-user="tpUser" @close="handleClose"/>
                        </CCollapse>
                        <CInput
                          v-model.trim="$v.user.PrimaryPhone.$model"
                          :invalid-feedback="errorMessage($v.user.PrimaryPhone)"
                          :is-valid="hasError($v.user.PrimaryPhone)"
                          size="sm"
                          type="tel"
                          :label="$t('label.mainPhone')"
                          :placeholder="$t('label.mainPhone')"
                          :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                          addLabelClasses="text-right required"
                          maxlength="20"
                        />
                        <CInput
                          v-model.trim="$v.user.SecondaryPhone.$model"
                          :invalid-feedback="errorMessage($v.user.SecondaryPhone)"
                          :is-valid="hasError($v.user.SecondaryPhone)"
                          size="sm"
                          type="tel"
                          :label="$t('label.secondaryPhone')"
                          :placeholder="$t('label.secondaryPhone')"
                          :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                          addLabelClasses="text-right"
                          maxlength="20"
                        />
                        <CInput
                          v-model.trim="$v.user.PrimaryEmail.$model"
                          :invalid-feedback="errorMessage($v.user.PrimaryEmail)"
                          :is-valid="hasError($v.user.PrimaryEmail)"
                          size="sm"
                          type="email"
                          :label="$t('label.mainEmail')"
                          :placeholder="$t('label.mainEmail')"
                          :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                          addLabelClasses="text-right required"
                          maxlength="100"
                        />
                        <CInput
                          v-model.trim="$v.user.SecondaryEmail.$model"
                          :invalid-feedback="errorMessage($v.user.SecondaryEmail)"
                          :is-valid="hasError($v.user.SecondaryEmail)"
                          size="sm"
                          type="email"
                          :label="$t('label.secondaryEmail')"
                          :placeholder="$t('label.secondaryEmail')"
                          :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                          addLabelClasses="text-right"
                          maxlength="100"
                        />
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CustomTab>
          <CustomTab :title="$t('label.asignUserRole')" :class="validRole &&  arrIdsAvaible.length == 0 ? 'tab-error' : ''">
            <CRow>
              <CCol sm="12">
                <CCard style="border: none;">
                  <CCardBody>
                    <CRow>
                      <CCol sm="6">
                        <div class="form-group form-row" rol="group">
                          <label class="text-right col-form-label col-sm-4 ml-3 pr-1 col-form-label-sm" for="pais">{{$t('label.branch')}}</label>
                          <div class="col-sm-7 input-group-sm">
                            <v-select 
                              :options="sucursalOptions"
                              label="BranchName"
                              :placeholder="$t('label.select')"
                              v-model.trim="sucursal"
                              :value.sync="sucursal"
                              :class="computedSelectBranch ? 'select--branch--correct' : (verifySelect ? 'select--branch--correct' : '') "
                              id="v-select-small"
                              style="width: 100%;"
                              :disabled="isSucursalEmpty"      
                            />
                            <div v-if="$v.sucursal.$error">
                              <div class='text-danger text' v-if="!$v.sucursal.required">
                                {{$t('label.required')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>
                      <CCol sm="6">
                        <div class="position-relative">
                          <div class="form-group form-row" rol="group">
                            <label class="text-right col-form-label col-sm-2 col-form-label-sm" for="pais">{{$t('label.yard')}}</label>
                            <div class="col-sm-7 input-group-sm pr-3">
                              <v-select 
                                :options="patioOptions"
                                label="YardName"
                                :placeholder="$t('label.select')"
                                v-model.trim="patio"
                                :value.sync="patio"
                                :class="computedSelectYard ? 'select--yard--correct' : (verifySelect ? 'select--yard--correct' : '') "
                                id="v-select-small"
                                style="width: 100%;"
                                :disabled="isPatioEmpty"
                              />
                              <div v-if="$v.patio.$error">
                                <div class='text-danger text' v-if="!$v.patio.required">
                                  {{$t('label.required')}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
  
              <CCol sm="12" class="border-right">
                <CCard>
                  <CCardHeader color="danger" text-color="white">
                    <h5 class="float-left">{{ $t('label.availableRoles') }}</h5>
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <CCol sm="12">
                        <!-- Datatable -->
                        <dataTableExtended
                          class="align-center-row-datatable"
                          :items="roleAvaibleComputed"
                          :fields="fieldsRolAvaible"
                          column-filter
                          :table-filter="tableText.tableFilterText"
                          :items-per-page-select="tableText.itemsPerPageText"
                          :noItemsView="tableText.noItemsViewText"
                          :items-per-page="tableText.itemsPerPage"
                          hover
                          small
                          sorter
                          pagination
                        >
                          <template #checked-header>
                            <td style="border-top:none">
                              <CInputCheckbox
                                custom
                                class="checkbook-filter mr-2"
                                :checked="roleAvaible"
                                @update:checked="selectAllAvailableRoles($event)"
                              />
                            </td>
                          </template>
                          <template #checked="{item}">
                            <td>
                              <CInputCheckbox
                                custom
                                class="checkbook-filter ml-1"
                                :checked="item.checked"
                                @update:checked="addRemoveIdAvaible(item,'father',$event)"
                              />
                            </td>
                          </template>
                          <template #Status="{ item }">
                            <td class="cell-center text-center">
                              <CBadge :color="getBadge(item.Status)">
                                {{ $t('label.'+item.Status) }}
                              </CBadge>
                            </td>
                          </template>
                          <template #show_details="{item}">
                            <td class="text-center">
                              <CButton
                                color="watch"
                                square
                                v-c-tooltip="{
                                content: $t('label.toView')+' '+$t('label.level'),
                                  placement: 'top-end',
                                }"
                                size="sm"
                                @click="toggleLevel1(item)" 
  
                              >
                                <CIcon name="eye" />
                              </CButton>
                            </td>
                          </template>
                          <template #details="{item}">
                          <CCollapse
                            :show="Boolean(item._toggled)"
                            :duration="collapseDuration"
                            > 
                            <CCardBody>
                              <CCol sm="12" lg="12">
                                <!-- Datatable sudmodule -->
                                <dataTableExtended
                                  class="align-center-row-datatable table-lv-2"
                                  :items="roleAvaibleComputedLv2"
                                  :fields="fieldsRolAvaibleLv2"
                                  :items-per-page="5"
                                  sorter
                                  pagination
                                  :table-filter="tableText.tableFilterText"
                                  :items-per-page-select="tableText.itemsPerPageText"
                                  :noItemsView="tableText.noItemsViewText"                                    
                                  >
                                  
                                  <template #checked="{item}">
                                    <td>
                                      <CInputCheckbox
                                        custom
                                        class="checkbook-filter ml-1"
                                        :checked="item.checked"
                                        @update:checked="addRemoveIdAvaible(item,'child',$event)"
                                      />
                                    </td>
                                  </template>
                                  <template #Status="{ item }">
                                    <td class="cell-center text-center">
                                      <CBadge :color="getBadge(item.Status)">
                                        {{ $t('label.'+item.Status) }}
                                      </CBadge>
                                    </td>
                                  </template>
                                </dataTableExtended>
                              </CCol>
                            </CCardBody>
                          </CCollapse>
                        </template>
                        </dataTableExtended>
                        <p v-if="this.arrIdsAvaible.length == 0"  class="mt-2 text-danger">
                          * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.role')}}
                        </p>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>   
          </CustomTab>    
        </CustomTabs> 
      </form>
      <template #footer>
        <CButton
          square
          color="add"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          square
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
  </template>
  
  <script>
  import UserValidations from '@/_validations/user/UserFormValidations';
  import UpperCase  from '@/_validations/uppercase-directive';
  import UserMixin, { TpUserMixin } from '@/_mixins/user';
  import ModaMixin from '@/_mixins/modal';
  import General from '@/_mixins/general';
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  
  import TpUserCollapse from './tp-user-collapse';
  
  //Data
  function data() {
    return {
      modalActive: this.modal,
      isSubmit: false,
      apiStateLoading:false,
      generated: false,
      userTypes: [],
      sucursales: [],
      patios: [],
      rolesSelect: [],
      UserRoleJson: [],
      UserRoleAvaibleJson: [],
      user: {
        TpUserId: '',
        Login: '',
        Password: '',
        UserCi: '',
        UserName: '',
        UserLastName: '',
        PrimaryPhone: '',
        SecondaryPhone: '',
        PrimaryEmail: '',
        SecondaryEmail: '',
      },
      sucursal: null,
      patio: null,
      roles: [],
      verifySelect:false,
      collapseOpen: '',
      collapseDuration: 0,
      RoleConfigJsonItems: [],
      roleAvaible: false, 
      roleAssigned: false, 
      arrIdsAvaible:[],
      arrIdsRoleAssigned: [],
      auxRoleAssignedd: [],
      idCollapse: false,
      tabIndex: 0,
      validRole: false
    }
  }
  
  //Methods
  
  function handleTab(tab) {
    this.tabIndex = tab;
  }
  
  function closeShow(item, level) {
    let moduleOpen;
    if (level == 1) {
      moduleOpen = this.roleAvaibleComputed.find((val) => val._toggled == true);
    } else if (level == 2) {
      moduleOpen = this.roleAvaibleComputedLv2.find((val) => val._toggled == true);
    }
    if (moduleOpen != undefined && moduleOpen.RoleId != item.RoleId) {
      moduleOpen._toggled = !moduleOpen._toggled;
    }
  }
  
  
  function selectAllAvailableRoles(event) {
    this.arrIdsAvaible = [];
  
    if (event) {
      this.roleAvaible = true;
      //SELECCIONA TODOS LOS ITEM NIVEL I
      this.UserRoleAvaibleJson.map((item) => {
        return item.RoleConfigJson.map(RoleConfigJson => {
          this.arrIdsAvaible.push({RoleId: item.RoleId, RoleConfigId: RoleConfigJson.RoleConfigId, Status: 1 });
        }) 
      });
    }else{
      this.roleAvaible = false;
    }
  }
  
  function selectAllAssignedRoles(event) {
    this.arrIdsRoleAssigned = [];
  
    if (event) {
      this.roleAssigned = true;
      //SELECCIONA TODOS ROLES ASIGNADOS
      this.UserRoleJson.map((item) => {
        this.arrIdsRoleAssigned.push({RoleId: item.RoleId, RoleConfigId: item.RoleConfigId, Status: 1});   
      });
    }else{
      this.roleAssigned = false;
    }
  }
  
  
  function addRemoveIdAvaible(item, tipo, event) {
    if( event ){
      //ADD
      //SI SE TILDA UN PADRE SE DEBEN MARCAR TODOS LOS HIJOS
      if ( tipo == 'father'){
        item.RoleConfigJson.map((RoleConfigJson) => {
          this.arrIdsAvaible.push({RoleId: item.RoleId, RoleConfigId: RoleConfigJson.RoleConfigId, Local: item.Local, Status: 1});
        });
      }
      
  
      //SI SE TILDA UN HIJO SE DEBE MARCAR EL PADRE
      if ( tipo == 'child' && this.collapseOpen != ''){
        this.arrIdsAvaible.push({RoleId: this.collapseOpen,RoleConfigId: item.RoleConfigId, Local: item.Local, Status: 1});
      }
    }else{
      //DELETE
      if ( tipo == 'father'){
        this.arrIdsAvaible = this.arrIdsAvaible.filter((e) => e.RoleId !== item.RoleId );
        // this.arrIdsAvaible.map((e) => {
        //   if(e.RoleId == item.RoleId){
        //     e.Status = 0;
        //   }
        // });
      }
  
          
      //SI SE DESTILDA UN PADRE SE DEBE DESMARCAN LOS HIJOS
      if ( tipo == 'child' && this.collapseOpen != ''){
        this.arrIdsAvaible = this.arrIdsAvaible.filter((e) => e.RoleConfigId !== item.RoleConfigId);
        // this.arrIdsAvaible.map((e) => {
        //   if(e.RoleConfigId == item.RoleConfigId){
        //     e.Status = 0;
        //   }
        // });
      }
    }
  
    this.roleAvaible = false;  
  
  }
  
  function addRemoveIdAssignedRoles(event, item) {
    this.roleAssigned = false;
    if (event) {
      let aux = this.arrIdsRoleAssigned.some((e) => {e.RoleConfigId === item.RoleConfigId});
      if(!aux){
        this.arrIdsRoleAssigned.push({RoleConfigId: item.RoleConfigId, Status: 1, Local: item?.Local ? item.Local : 1}); 
      }
    } else {
      this.arrIdsRoleAssigned.map((e) => {
        if(e.RoleConfigId == item.RoleConfigId){
          e.Status = 0;
        }
      });
  }
  
  }
  
  async function toggleLevel1(item) {
    this.apiStateLoading = true;
    await this.closeShow(item, 1);
  
    let modulePadre = this.UserRoleAvaibleJson.find(
      (val) => val.RoleId === item.RoleId
    );
   
    this.RoleConfigJsonItems = modulePadre.RoleConfigJson;
  
    this.collapseOpen = modulePadre?.RoleId ? modulePadre.RoleId : '';
  
    item._toggled = !item._toggled;
    if (item._toggled == true) {
        this.idCollapse = item.RoleId;
      }else{ 
        this.idCollapse = '';
      };
    
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.apiStateLoading = false;
    });
  }
  
  
  
  
  function submit() {
    try {
      this.isSubmit = true;
      this.verifySelect  =  true;
      this.apiStateLoading= true;
      this.$v.$touch();
  
      if (this.$v.$error || this.arrIdsAvaible.length == 0) {
          this.isSubmit = false;
          this.validRole = true;
          this.apiStateLoading = false;
          throw this.$t('label.errorsPleaseCheck');
      }
      
      let aux = []
  
      let totalIds = [...this.arrIdsAvaible]
  
      totalIds.map((e) => {
        if((e.Local == 0) || (e.Local == 1 && e.Status == 1) ){
          aux.push(e) 
        }
      });
  
      let UserJson = {
        ...this.user,
        UserRolJson: aux
      };
  
      
      this.$http.ejecutar('POST', 'User-insert', UserJson, { root: 'UserJson' })
      .then(response => {
        this.$emit('submited');
        this.modalActive = false;
        this.apiStateLoading = false;
        this.isSubmit = false;
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: response.data.data[0].Response,
          type: "success"
        });
        this.resetForm();
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.isSubmit = false;
        this.apiStateLoading = false;
      }).then(() => {
        this.isSubmit = false;
        this.apiStateLoading = false;
      });
    }catch (err) {
      this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.apiStateLoading = false;
        this.isSubmit = false;
    }
  }
  
  function resetForm() {
    this.user.TpUserId = '';
    this.user.Login = '';
    this.user.Password = '';
    this.user.UserCi = '';
    this.user.UserName = '';
    this.user.UserLastName = '';
    this.user.PrimaryPhone = '';
    this.user.SecondaryPhone = '';
    this.user.PrimaryEmail = '';
    this.user.SecondaryEmail = '';
    this.userTypes = []
    this.patios = [];
    this.sucursales = [];
    this.sucursal = null;
    this.patio = null;
    this.roles = [];
    this.generated = false;
    this.tableShow = false;
    this.verifySelect = false;
    this.tpUserEdit = false;
    this.tpUser = {};
    this.showCollapse = false;
    this.tabIndex = 0;
    this.UserRoleJson= [],
    this.UserRoleAvaibleJson= [],
    this.arrIdsAvaible= [],
    this.arrIdsRoleAssigned = [],
    this.roleAvaible = false;
    this.roleAssigned = true;
    this.idCollapse = false;
    this.validRole = false;
    this.$v.$reset();
  }
  
  function generatedLogin() {
    if(!this.generated) {
      this.generated = true;
      let first = this.user.UserName.charAt(0);
      let second = '';
      if(this.user.UserLastName.indexOf(' ') != -1)
        second = this.user.UserLastName.substr(0, this.user.UserLastName.indexOf(' '));
      else
        second = this.user.UserLastName;
  
      this.user.Login = `${first}${second}`;
      this.$v.user.Login.$touch();
    }
  }
  
  //Computeds:
  function roleAvaibleComputed() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if (this.UserRoleAvaibleJson.length > 0) {
      
      return this.UserRoleAvaibleJson.map((item) => {
        
        if (this.idCollapse==item.RoleId) {
          this.RoleConfigJsonItems = item.RoleConfigJson;
        };
  
        let checkeds = this.arrIdsAvaible.some((e) => e.RoleId == item.RoleId);
  
        return {
          ...item,
          RoleName:  item[`RoleName${_lang}`],
          _classes:'table-dark',
          _toggled: this.idCollapse ? (this.idCollapse==item.RoleId ? true : false) : false,
          checked: checkeds,
        };
      });
    }
  }
  
  function roleAvaibleComputedLv2() {
    if (this.RoleConfigJsonItems.length > 0) {
      return this.RoleConfigJsonItems.map((item) => {
        let checkeds = this.arrIdsAvaible.some((e) => e.RoleConfigId == item.RoleConfigId);
  
        return {
          ...item,
          YardName: item.YardName ? item.YardName : 'N/A',
          _toggled: false,
          checked: checkeds,
        };
      });
    }
  }
  
  
  function rolesAssignedComputed() {
  
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if (this.UserRoleJson.length > 0) {
    return this.UserRoleJson.map((item) => {
      let checkeds = this.arrIdsRoleAssigned.find((e) => e.RoleConfigId == item.RoleConfigId)?.Status == 1 ? true : false;
      return {
        ...item,
        RoleName: item[`RoleName${_lang}`],
        checked: checkeds,
        _classes:'table-dark'
      };
    });
  }
  }
  
  function computedSelectBranch() {
    if (this.sucursal === null||this.sucursal === '') {
      return false;
    }else{
      return true;
    }
  }
  
  function computedSelectYard() {
    if (this.patio === null||this.patio === '') {
      return false;
    }else{
      return true;
    }
  }
  
  function fieldsRolAvaible() { 
    return [
      { key: 'checked',label: 'Sel',sorter: false, filter: false,_style:'width:3%;',},
      { key: 'RoleName', label: this.$t('label.role'), _style: 'width:70%;', _classes: 'text-center center-cell'},
      { key: 'show_details',label: '',_style: 'min-width:45px;', _classes: 'text-center center-cell', sorter: false,  filter: false,}
    ];
  }
  
  function fieldsRolAvaibleLv2() { 
    return [
      { key: 'checked',label:'',sorter: false,_style:'width:3%;',},
      { key: 'BranchName', label: this.$t('label.branch'), _style: 'width:35%;', _classes: 'text-center center-cell'},
      { key: 'YardName', label: this.$t('label.yard'), _style: 'width:35%;', _classes: 'text-center center-cell'},
    ];
  }
  
  function fieldsRolAssigned() { 
    return [
      { key: 'checked',label: 'Sel',sorter: false, filter: false,_style:'width:3%;',},
      { key: 'RoleName', label: this.$t('label.role'), _style: 'width:20%;', _classes: 'text-center center-cell'},
      { key: 'BranchName', label: this.$t('label.branch'), _style: 'width:20%;', _classes: 'text-center center-cell'},
      { key: 'YardName', label: this.$t('label.yard'), _style: 'width:20%;', _classes: 'text-center center-cell'},
    ];
  }
  
  export default {
    name: 'add-modal',
    mixins: [UserMixin, TpUserMixin, ModaMixin,General],
    props: {
      modal: Boolean
    },
    components: { TpUserCollapse, CustomTabs, CustomTab },
    data,
    validations: UserValidations,
    directives: UpperCase,
    methods: {
      handleTab,
      closeShow,
      selectAllAvailableRoles,
      selectAllAssignedRoles,
      addRemoveIdAssignedRoles,
      addRemoveIdAvaible,
      toggleLevel1,
      submit,
      resetForm,
      generatedLogin,
    },
    computed: {
      roleAvaibleComputed,
      roleAvaibleComputedLv2,
      rolesAssignedComputed,
      computedSelectBranch,
      computedSelectYard,
      fieldsRolAvaible,
      fieldsRolAvaibleLv2,
      fieldsRolAssigned
    },
    watch: {
      modal: async function (val) {
        this.modalActive = val;
        if(val){
          this.getUserTypes();
        }else{
          this.verifySelect = false;
        }
      },
      sucursal: function(newSucursal){
        if(newSucursal){
          this.getPatiosSelect(newSucursal.CompanyBranchId);
          this.rolesData = [];
        }else{
          this.rolesData = [];
          this.patios = [];
          this.patio = null;
          this.getAvailableRoles();
        }
      },
      patio: function(newPatio) {
        if(this.sucursal && newPatio){
          this.getAvailableRoles(this.sucursal.CompanyBranchId, newPatio.YardId);
        }else{
          this.rolesData = [];
        }
      },
       tabIndex: async function (val){
        if(val == 0){
          await this.getUserTypes();
        }else if(val == 1){
          await this.loadData();
          await this.getAvailableRoles();
        }
      }, 
    }
  }
  </script>
  <style scoped>
  .invalid {
    display: block;
  }
  a.table-link{
    color: #6c868d;
    text-decoration: none;
    transition: color .4s ease-in-out;
  }
  
  a:hover.table-link{
    color: #17b6df;
  }
  .arrow-icon {
    transition: transform 0.15s ease-in;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .arrow-icon.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  </style>
  
  <style lang="scss">
  .select--branch--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
  .select--branch--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
  }
  .select--yard--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
  .select--yard--error {
    .vs__dropdown-toggle {
        border-color: #e55353 !important;
    }
  }
  
  .text{
     font-size: 11.2px !important;
  }
  
  .text-table .custom-tab-content {
    background: #fff;
    border: 0;
    outline: none;
    border-radius: 5px;
    border-top-left-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .tab-error .custom-tab-link {
    background: #e1373F;
    border-color: #e1373F !important;
  }
   
  </style>