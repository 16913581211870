<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
        <b>{{ titulo }}</b>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CInput
            v-model.trim="$v.tpUser.TpUserNameEs.$model"
            v-uppercase
            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
            :invalid-feedback="errorMessage($v.tpUser.TpUserNameEs)"
            :is-valid="hasError($v.tpUser.TpUserNameEs)"
            size="sm"
            :label="$t('label.userType')+' '+('(ES)')"
            :placeholder="$t('label.userTypeName')+' '+('(ES)')"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12">
        <CInput
            v-model.trim="$v.tpUser.TpUserNameEn.$model"
            v-uppercase
            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
            :invalid-feedback="errorMessage($v.tpUser.TpUserNameEn)"
            :is-valid="hasError($v.tpUser.TpUserNameEn)"
            size="sm"
            :label="$t('label.userType')+' '+('(EN)')"
            :placeholder="$t('label.userTypeName')+' '+('(EN)')"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12" v-if="Edit">
          <CSelect
            :value.sync="tpUser.Status"
            :is-valid="statusSelectColor"
            :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
            size="sm"
            :label="$t('label.status')"
            addLabelClasses="text-right"
            :options="statusOptions"
            class="mt-1"
          />
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter class="d-flex justify-content-end">
      <CButtonGroup>
        <CButton
          v-if="!Edit"
          size="sm"
          shape="square"
          color="add"
          class="d-flex align-items-center mr-2"
          :disabled="isSubmit"
          @click="submit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          v-else
          size="sm"
          shape="square"
          color="add"
          class="d-flex align-items-center mr-2"
          :disabled="isSubmit"
          @click="statusConfirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          size="sm"
          shape="square"
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeCollapse"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </CButtonGroup>
    </CCardFooter>
  </CCard>
</template>

<script>
import TpUserFormValidation from '@/_validations/user/TpUserFormValidation';
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal'

//Data
function data() {
  return {
    isSubmit: false,
    tpUser: {
      TpUserId: '',
      TpUserNameEs: '',
      TpUserNameEn: '',
      Status: 1
    }
  }
}

//Method
function submit() {  
  try {
    this.$v.$touch();
    this.isSubmit = true;
    let metodo = this.Edit ? 'PUT':'POST';
    let ruta = this.Edit ? 'UserTp-update' : 'UserTp-insert'; 
    if (this.$v.$error) {
        this.isSubmit = false;
        this.apiStateLoading = false;
        throw this.$t('label.errorsPleaseCheck');
    }
    let TpUserJson = this.Edit
    ? {
        TpUserId: this.tpUser.TpUserId,
        TpUserNameEs: this.tpUser.TpUserNameEs,
        TpUserNameEn: this.tpUser.TpUserNameEn,
        Status: this.tpUser.Status
      }
    : { TpUserNameEs: this.tpUser.TpUserNameEs, 
        TpUserNameEn: this.tpUser.TpUserNameEn
      };

  this.$http.ejecutar(metodo, ruta, TpUserJson, { root: 'TpUserJson' })
  .then(response => {
    this.notifySuccess({
      text: response.data.data[0].Response,
    });
    this.resetForm();
  }).catch(err => {
    this.notifyError({
      text: err,
    });
  }).then(() => {
    this.isSubmit = false;
  });
  } catch (err) {
    this.notifyError({
      text: err,
    });
  } 
}
function closeCollapse() {
  this.resetForm();
}
function hasError(field){
  return field.$dirty ? !field.$error : null;
}
function resetForm() {
  this.tpUser.TpUserId = '';
  this.tpUser.TpUserNameEs = '';
  this.tpUser.TpUserNameEn = '';
  this.tpUser.Status = 1;
   this.isSubmit = false;
  this.$v.$reset();
  this.$emit('close');
}
function setForm(newTpUser) {
  this.tpUser.TpUserId = newTpUser.TpUserId;
  this.tpUser.TpUserNameEs = newTpUser.TpUserNameEs;
  this.tpUser.TpUserNameEn = newTpUser.TpUserNameEn;
  this.tpUser.Status = newTpUser.FgActTpUser ? 1 : 0;
  this.$v.$touch();
}

function statusConfirmation(){
  let status = this.TpUser.FgActTpUser?1:0;
  if(this.tpUser.Status === status)
    this.submit();
  else{
    this.$swal.fire(this.alertProperties({
      text: `${this.$t('label.changeStatusQuestion')} ?`, 
    })).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      }
    });
  }
}

//Computed
function titulo() {
  return this.Edit ? this.$t('label.edit')+' '+this.$t('label.userType'):this.$t('label.add')+' '+this.$t('label.userType');
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function statusSelectColor(){
  return this.tpUser.Status === 1;
}
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}

export default {
  name:  'tipo-usuario',
  mixins:[ModalMixin],
  props: {
    TpUser: {
      type: Object,
      default: () => {}
    },
    Edit: {
      type: Boolean,
      default: false
    }
  },
  data,
  validations: TpUserFormValidation,
  directives: UpperCase,
  methods: {
    submit,
    closeCollapse,
    hasError,
    resetForm,
    setForm,
    statusConfirmation
  },
  computed: {
    titulo,
    statusOptions,
    statusSelectColor,
    isSubmitValid
  },
  watch: {
    Edit: function(newEdit){
      if(newEdit && Object.keys(this.TpUser).length !== 0)
        this.setForm(this.TpUser);
    },
    TpUser: function(newTpUser) {
      if(this.Edit && Object.keys(newTpUser).length !== 0)
        this.setForm(newTpUser);
    }
  }
}
</script>

<style scoped>

</style>