import { mapState } from 'vuex';
import { tableTextTranslatedHelpers } from '@/_helpers/funciones';


//Data:
function data() {
  return {
  }
}

//Meodos:
async function loadData(flag) {
  this.apiStateLoading = true;
  let idEmpresa = this.userState.CompanyId;
  let peticiones = flag
  ?[
    this.$http.ejecutar('GET', 'Branch-list-by-company', {CompanyId: idEmpresa, Filter: 'ACTIVO'}),
    this.$http.ejecutar('GET', 'UserRole-by-id', { Filter: 'ALL' })
  ]
  :[
    this.$http.ejecutar('GET', 'Branch-list-by-company', {CompanyId: idEmpresa, Filter: 'ACTIVO'}),
  ];

  await Promise.all(peticiones)
  .then((responses) => {
    this.sucursales = responses[0].data.data;
    
    if(flag){
      this.roles = responses[1].data.data
        .filter(rol => rol.Status === 'ACTIVO')
        .map((rol) => Object.assign({}, rol, {
          RolInfo: rol.YardId
            ?`${rol.RoleName} - ${rol.BranchName} - ${rol.YardName}`
            :`${rol.RoleName} - ${rol.BranchName}`
        }));
      this.rolesViejos = responses[3].data.data;
    }
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}
function getUserTypes(){
  this.apiStateLoading = true;
  this.$http.ejecutar('GET', 'UserTp-list', { Filter: 'ACTIVO' })
  .then(response => {
    this.userTypes = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.apiStateLoading = false;
  });
}
function getSucursales() {
  this.apiStateLoading = true;
  let id = this.userState.CompanyId;
  this.$http.ejecutar('GET', 'Branch-list-by-company', {CompanyId: id, Filter: 'ACTIVO'})
  .then(response => {
    this.sucursales = response.data.data;
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.apiStateLoading = false;
  });;
}
function getPatiosSelect(id){
  this.apiStateLoading = true;
  this.$http.ejecutar('GET', 'Yard-list', {CompanyBranchId: id, Filter: 'ACTIVO'})
  .then(response => {
    this.patios = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.apiStateLoading = false;
  });
}
function getAvailableRoles(idSucursal = '', idPatio = '',  userId = ''){
  this.apiStateLoading = true;
  this.$http.ejecutar('GET', `Role-Config-list?CompanyBranchId=${idSucursal}&YardId=${idPatio}&UserId=${userId}`)
  .then(response => {
    this.rolesData = response.data.data;
    let array = this.rolesData.find((val) => val.RoleId == this.RoleId);
    this.UserRoleJson = array.UserRoleJson;

    let array2 = this.rolesData.find((val) => val.RoleId == this.RoleId);
    this.UserRoleAvaibleJson = array2.UserRoleAvaibleJson;

    this.UserRoleAvaibleJson.map((item) => {
      item.Local = 1;
    });

    this.UserRoleJson.map((item) => {
      if (item.UserRoleId !== null ) {
        item.Status = 1;
        item.Local = 0;
        this.arrIdsRoleAssigned.push({RoleConfigId: item.RoleConfigId, Status: 1, Local: 0});
      }else{
        item.Local = 1;
        this.arrIdsRoleAssigned.push({RoleConfigId: item.RoleConfigId, Status: 1, Local: 1});
      }
    });

  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.apiStateLoading = false;
  });
}
function getRolesSucursalPatio(idSucursal, idPatio) {
  this.apiStateLoading = true;
  let userId = this.userState.UserId;
  this.$http.ejecutar('GET', 'Role-Config-list', {CompanyBranchId: idSucursal, YardId: idPatio, UserId: userId})
  .then(response => {
    let items = response.data.data;
    let array = items.find((val) => val.RoleId == this.RoleId);
    this.UserRoleJson = array.UserRoleJson;
    this.apiStateLoading = false;
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.apiStateLoading = false;
  });
}
function hasError(field){
  return field.$dirty ? !field.$error : null;
}
function touchRequiredValues() {
  this.$v.user.TpUserId.$touch();
  this.$v.user.UserName.$touch();
  this.$v.user.UserLastName.$touch();
  this.$v.user.Password.$touch();
  this.$v.user.Login.$touch();
  this.$v.user.UserCi.$touch();
  this.$v.user.PrimaryPhone.$touch();
  this.$v.user.PrimaryPhone.$touch();
  this.$v.roles.$touch();

  if(this.$v.user.SecondaryPhone.$model)
    this.$v.user.SecondaryPhone.$touch();

  if(this.$v.user.SecondaryEmail.$model)
    this.$v.user.SecondaryEmail.$touch();
}


//Computeds
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function typesOptions() {
  let _lang = this.$i18n.locale;
  return this.userTypes.map((type) => Object.assign({}, type, {
    value: type.TpUserId,
    label: _lang=='en' ? type.TpUserNameEn : type.TpUserNameEs,
  }));
}
function sucursalOptions() {
  return this.sucursales.map((sucursal) => Object.assign({}, sucursal, {
    value: sucursal.CompanyBranchId,
    label: sucursal.BranchName
  }));
}
function patioOptions() {
  return this.patios.map((patio) => Object.assign({}, patio, {
    value: patio.YardId,
    label: patio.YardName
  }));
}
function isPatioEmpty(){
  return this.patios.length == 0;
}
function isTypesEmpty(){
  return this.userTypes.length == 0;
}
function isSucursalEmpty(){
  return this.sucursales.length == 0;
}
function isRolesEmpty(){
  return this.rolesSelect.length == 0;
}
function trackBy(){
  return this.sucursal === null? 'RoleId':'RoleConfigId'; //Verificar
}

function tableText(){
  return tableTextTranslatedHelpers(this);
}

export default {
  data,
  methods: {
    hasError,
    loadData,
    getUserTypes,
    getSucursales,
    getPatiosSelect,
    getAvailableRoles,
    getRolesSucursalPatio,
    touchRequiredValues,
  },
  computed: {
    isSubmitValid,
    typesOptions,
    sucursalOptions,
    patioOptions,
    isPatioEmpty,
    isSucursalEmpty,
    isTypesEmpty,
    isRolesEmpty,
    trackBy,
    tableText,
    ...mapState({
        userState: state => state.auth.user,
    })
  }
}


export const TpUserMixin = {
  data() {
    return{
      showCollapse: false,
      tpUser: {},
      tpUserEdit: false
    }
  },
  methods: {
    openCollapse(add) {
      if(!add)
        this.tpUserEdit = true;
  
      this.showCollapse = true;
    },
    handleClose() {
      this.showCollapse = false;
      this.tpUserEdit = false;
      this.getUserTypes();
    },
    handleTpUserSelect() {
      this.tpUser = this.userTypes.find((tipo) => tipo.TpUserId == this.user.TpUserId);
    }
  },
  computed: {
    isCollapseOpen() {
      return this.showCollapse;
    },
    isTpUserEditInvalid() {
      return Object.keys(this.tpUser).length === 0 || this.isCollapseOpen;
    }
  }
}