var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(_vm._s(_vm.$t('label.userList')))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.user'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.user'),\n                placement: 'top-end'\n              }"}],staticClass:"d-flex align-items-center",attrs:{"shape":"square","color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"loading":_vm.isLoading,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"active-page":_vm.activePage,"pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"RowNumber",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(item.RowNumber)+" ")])]}},{key:"StatusText",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.StatusText)}},[_vm._v(" "+_vm._s(item.StatusText)+" ")])],1)]}},{key:"Details",fn:function({item, index}){return [_c('td',{staticClass:"py-2"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.edit')+' '+_vm.$t('label.user'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.edit')+' '+$t('label.user'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.toView')+' '+'ROLES',
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.toView')+' '+'ROLES',\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleRoles(item, index)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.transactions')+' '+_vm.$t('label.user'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.transactions')+' '+$t('label.user'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"color":"dark","shape":"square","size":"sm"},on:{"click":function($event){return _vm.toggleTransac(item)}}},[_c('CIcon',{attrs:{"name":"cil-chevron-circle-up-alt"}})],1)],1)]}}])})],1)],1)],1)],1)],1),_c('add-modal',{attrs:{"modal":_vm.modalAdd},on:{"update:modal":function($event){_vm.modalAdd=$event},"submited":_vm.handleSubmit}}),_c('edit-modal',{attrs:{"modal":_vm.modalEdit,"user-data":_vm.user},on:{"update:modal":function($event){_vm.modalEdit=$event},"submited":_vm.handleSubmit}}),_c('roles-modal',{attrs:{"modal":_vm.modalRoles,"user-data":_vm.rolesUser},on:{"update:modal":function($event){_vm.modalRoles=$event}}}),_c('transacciones-modal',{attrs:{"modal":_vm.modalTransacciones,"transaccionesUser":_vm.transaccionesUser,"transaccionesUserId":_vm.transaccionesUserId},on:{"update:modal":function($event){_vm.modalTransacciones=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }