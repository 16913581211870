import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';

async function getPdf(rowData, titulo, colDelete, tableHeader) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    //if (isUndefined(un)) un = 'TON';
    if (tableHeader) {
        for (let index = 0; index < tableHeader.length; index++) {
            columns.push({
                title: tableHeader[index].title,
                dataKey: tableHeader[index].dataKey
            });
        }
    }else{
        let columnsDefs = [];
        if (this.gridApi!==undefined) {
            columnsDefs = this.gridApi.getColumnDefs();
        }

        if(columnsDefs.length > 0){
            if(colDelete)
            columnsDefs.splice(0, colDelete);
        }
    
        for (let index = 0; index < columnsDefs.length; index++) {
            //if (this.columnDefs[index].headerName.toUpperCase() == "WEIGTH") this.columnDefs[index].headerName = this.columnDefs[index].headerName + " (" + un + ")";
            columns.push({
                title: columnsDefs[index].headerName,
                dataKey: columnsDefs[index].field
            });
        }
    }

    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let largo = columns.length*70;
    let resta = 0;
    let tcentro = 550;
    let font = 8;
    let widthImage = 59;
    let positionX = 115; 
    let sizeText = 9;
    let tableWidthBody = 'auto';
    
    if (columns.length > 9){
        widthImage = 120;
        positionX = 165;
        sizeText = 10;
        tableWidthBody = 970;
    }

    if (columns.length > 14) font=7;

    if (largo < 700) {
        largo = 700;
        resta = 350;
        tcentro = 350;
    //} else if ((largo > 600) && (largo < 850)) {
    //    largo = 850;
    } else {
        largo = 1050;
    }
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [600, largo],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);
        doc.setFontSize(8);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, 570, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {           
                align: 'right'
            })
        }
    }

    doc.setDrawColor("#fff");
    //doc.rect(40, 40, 65, 70);
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    doc.setDrawColor("#fff");
    doc.rect(positionX, 40, 905 - resta, 40);
    doc.setFontSize(sizeText);
    doc.text(this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName, tcentro, 65, 'center');

    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 970-resta, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 970-resta, 25, "FD");
    doc.setFontSize(sizeText);
    doc.text(titulo, tcentro, 125, 'center');

    /*if (data.Cont20FtDeck == "DECK") {
        
    }*/
    //let arreglo = rowData.shift();
    let inicio = 145;
    if (rowData[0].Cont20FtDeck == "DECK") {
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");
        doc.setFillColor("#1a3760");
        doc.rect(150, 145, 320, 20, "FD");
        doc.text(rowData[0].Cont20FtDeck, 280, 160, 'center');
        doc.setFillColor("#ec631b");
        doc.rect(437, 145, 285, 20, "FD");
        doc.text(rowData[0].Cont20FtHold, 580, 160, 'center');
        doc.setFillColor("#5d6164");
        doc.rect(722, 145, 288, 20, "FD");
        doc.text(rowData[0].Cont20FtTotal, 880, 160, 'center');
        rowData.shift();
        inicio = 165; 
    } else if (rowData[0].Cont20Full == "20F") {
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");
        doc.setFillColor("#1a3760");
        doc.rect(232, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont20Full, 295, 160, 'center');
        doc.setFillColor("#1a3760");
        doc.rect(363, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont20Empty, 425, 160, 'center');
        
        doc.setFillColor("#ec631b");
        doc.rect(495, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont40Full, 550, 160, 'center');
        doc.setFillColor("#ec631b");
        doc.rect(620, 145, 142, 20, "FD");
        doc.text(rowData[0].Cont40Empty, 680, 160, 'center');
        
        doc.setFillColor("#5d6164");
        doc.rect(750, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont45Full, 810, 160, 'center');
        doc.setFillColor("#5d6164");
        doc.rect(880, 145, 130, 20, "FD");
        doc.text(rowData[0].Cont45Empty, 938, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].CtaFunctionCode == "CONTACT INFORMATION") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");
        
        doc.setFillColor("#1a3760");
        doc.rect(190, 145, 137, 20, "FD");
        doc.text(rowData[0].CtaFunctionCode, 260, 160, 'center');
        
        doc.setFillColor("#1a3760");
        doc.rect(327, 145, 90, 20, "FD");
        doc.text("CONTACT", 370, 160, 'center');
        
        doc.setFillColor("#ec631b");
        doc.rect(417, 145, 275, 20, "FD");
        doc.text(rowData[0].HanDescriptionCode, 530, 160, 'center');
        
        doc.setFillColor("#5d6164");
        doc.rect(692, 145, 190, 20, "FD");
        doc.text(rowData[0].TsrServicePriorityCode, 780, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].section == "totalLoad") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(151, 145, 255, 20, "FD");
        doc.text("DECK", 280, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(403, 145, 257, 20, "FD");
        doc.text("HOLD", 520, 160, 'center');
        rowData.shift();
        inicio = 165; 
    } else if (rowData[0].total == "DELAY_LIST") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(860, 145, 38, 20, "FD");
        doc.text("FULL", 875, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(897, 145, 38, 20, "FD");
        doc.text("EMPTY", 915, 160, 'center');
        rowData.shift();
        inicio = 165; 
    } else if (rowData[0].section == "transport_summary") {
        let _lang = this.$i18n.locale;
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        let posX =_lang=='en' ? 305 : 274;
        let ancho =_lang=='en' ? 325 : 344; 

        doc.setFillColor("#1a3760");
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.orders'), posX+173, 160, 'center');
       
        doc.setFillColor("#ec631b");
        posX =_lang=='en' ? 620 : 618;
        ancho =_lang=='en' ? 210 : 228;
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.times'), posX+105, 160, 'center');
        
        doc.setFillColor("#5d6164");
        posX =_lang=='en' ? 827 : 845;
        ancho =_lang=='en' ? 183 : 165;
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.ton')+'S', posX+75, 160, 'center');

        rowData.shift();
        inicio = 165; 
    }
    
    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        columnWidth: 70,
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: { bottom: 45 },
        tableWidth: tableWidthBody,
        styles: { 
            cellPadding: 1, 
            fontSize: font, 
            halign: "center", 
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
        /*styles: {
            overflow: 'linebreak',
            columnWidth: 'wrap',
           // font: 'arial',
            fontSize: font,
            cellPadding: 2,
            halign: "center",
            overflowColumns: 'linebreak'
        },*/
    });
  
    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getReporExcel(rowData, titulo, excel, val, colDelete, tableHeader) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    if (tableHeader) {
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }else if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    if(colDelete)
        {
            keyColumns.splice(0, colDelete);
            columnsDefs.splice(0, colDelete);
            y = y-colDelete;
        }
    //if (isUndefined(un)) un = 'TON';
    if (excel) {
        worksheet.mergeCells('A1:B2');
        const response1 = await this.$http.getFile('base64', {
            path:this.user.LogoRoute.replace('Public/','', null, 'i')
        });
        
        const divisiones1 = this.user.LogoRoute.split(".");
        const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
        const imageId = workbook.addImage({
            base64: myBase64Image,
            extension: divisiones1[1],
        });
        worksheet.addImage(imageId, 'A1:B2');
        worksheet.getCell("A1").border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        const rowIndex1 = worksheet.getRow(1);
        rowIndex1.height = 100;

        worksheet.mergeCells("C1:" + numeroLetra(y) + "2");

        const customCellCompanyBranch = worksheet.getCell("C1");

        const row1 = worksheet.getRow(1);
        row1.height = 30;
        customCellCompanyBranch.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCellCompanyBranch.note = {
            margins: {
                insetmode: 'auto',
                inset: [10, 10, 5, 5]
            }
        }
        customCellCompanyBranch.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'f2f2f2'
            }
        }
        customCellCompanyBranch.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
        customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / val.length);
        let Rango = [];
        let Extra = (y-(division*val.length));
        for (let index = 0 , Num = 1; index < val.length; index++, Num++) {
            if (index < (val.length-1)) {
                if (index==0) {
                    if (Extra>=division) {
                        Num++;
                        Rango.push({
                            rango1: numeroLetra(division*Num),
                            rango11: numeroLetra((division*Num) +1),
                        })
                    }else{
                        Rango.push({
                            rango1: numeroLetra(division),
                            rango11: numeroLetra((division) +1),
                        })
                    }
                }else{
                    Rango.push({
                        rango1: numeroLetra(division*Num),
                        rango11: numeroLetra((division*Num) +1),
                    })
                }    
            }else{
                Rango.push({
                    rango1: numeroLetra(y),
                })
            }
        }
        
        //rango de la filas
        worksheet.getRow(4).height = 35
        worksheet.mergeCells("A4:" + Rango[0].rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = val[0];
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        for (let index = 0; index < (Rango.length-1); index++) {
            worksheet.mergeCells(Rango[index].rango11 + "4:" + Rango[index+1].rango1 + "4");
            let Cell = worksheet.getCell(Rango[index].rango11+"4")
            Cell.height = 50;
            Cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            Cell.value = val[index+1];
            Cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            Cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }

        //linea
        let linea = (Rango.length - 1);
        worksheet.mergeCells("A5:"+Rango[linea].rango1+"5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;
    
    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }
        
        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";
        
        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data; 
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}        
        i++;
    })

    rowData.map(async function(data){
        let dataRow = worksheet.addRow();
        let indice = 0;
            
        keyColumns.map(function (data2) {
            let cell = dataRow.getCell(indice + 1);
            cell.value = data[data2];
            if (indice!=0 && !isNaN(data[data2])) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
            }
            //if (cell.value==)
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
        })
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

export default {
    methods: {
        getPdf,
        getReporExcel,
        numeroLetra,
    },
    computed:{
      ...mapState({
          user: state => state.auth.user,
          branch: state => state.auth.branch,
      }),
    },
  }